import { cas } from '@/api/api';
// import { Loading } from 'element-ui';
class Tools {
  static #isLocalStaus = localStorage.getItem('user') ? true : false;
  static #test_user_info = { type: 'test', info: '' };
  constructor() {}

  static getParamsValue(name) {
    let reg = `(^|&)${name}=([^&]*)(&|$)`;
    let r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  }

  static isLocal() {
    let status = localStorage.getItem('user') ? true : false;
    return status;
  }

  static getUsetInfo() {
    let userInfo = {};
    if (Tools.isLocal()) {
      userInfo = JSON.parse(localStorage.getItem('user'));
    } else {
      return window.location.replace('/');
    }
    return userInfo;
  }

  static logout() {
    sessionStorage.clear();
    localStorage.clear();
    // window.location.replace(process.env.VUE_APP_CAS_URL + `http://${window.location.host}/`);
    window.location.replace(process.env.VUE_APP_CAS_URL + `https://${window.location.host}/`);
  }

  static isLogin(to, from, next, loadingInstance) {
    if (Tools.isLocal()) {
      loadingInstance.close();
      next();
    } else {
      if (Tools.getParamsValue('ticket') == null) {
        // window.location.replace(process.env.VUE_APP_CAS_URL + `http://${window.location.host}/`);
        window.location.replace(process.env.VUE_APP_CAS_URL + `https://${window.location.host}/`);
      } else {
        let params = {
          ticket: Tools.getParamsValue('ticket'),
          url: `https://${window.location.host}/`
          // url: `https://${window.location.host}/`
        };
        cas(params)
          .then((res) => {
            loadingInstance.close();
            if (res.code == '0000') {
              console.log(res,1111111111)
              localStorage.setItem('user', JSON.stringify(res.data));
              next();
            } else if (res.code == '1007') {
              Tools.logout()
            }
          })
          .catch((err) => {
            loadingInstance.close();
            console.log(err);
          });
      }
    }
  }
}

export default Tools;
