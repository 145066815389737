<template>
  <v-app>
    <v-main>
      <v-container class="del-pb">
        <v-row justify="center">
          <v-col cols="12" sm="6">
            <v-tabs
              fixed-tabs
              @change="_reload"
              v-model="toggle_index"
              background-color="transparent"
              color="#0BB1EE"
              left
            >
              <v-tabs-slider color="#0BB1EE"></v-tabs-slider>
              <v-tab v-for="nav in list_nav" :key="nav.name">
                {{ nav.name }}
              </v-tab>
            </v-tabs>

            <v-row>
              <v-col
                cols="12"
                class="d-flex flex-row align-center del-pb justify-space-around"
              >
                <v-col cols="11" class="del-pl">
                  <v-text-field
                    @keyup.13="handleKeyUp"
                    background-color="white"
                    v-model="search_data.search"
                    single-line
                    class="del-text-field-msg pa-0"
                    outlined
                    label="请输入编号、工程名称、改造地点、申请人"
                    dense
                    clearable
                    prepend-inner-icon="mdi-magnify"
                  >
                  </v-text-field>
                </v-col>
                <v-btn
                  class="rounded-lg"
                  color="#006A91"
                  dark
                  small
                  tile
                  elevation="0"
                  @click.stop="_getProjectListAct2"
                  fab
                >
                  <v-icon dark> mdi-sort </v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <!-- 列表 -->
            <div
              class="infinite-list"
              v-infinite-scroll="load"
              style="overflow: auto; height: 90vh"
            >
              <p
                v-if="desserts.length == 0"
                class="grey--text mx-auto text-center pt-10"
              >
                暂无数据
              </p>
              <!-- 新增 -->
              <v-card
                @click.stop="
                  _itemAtcion({
                    type: toggle_index ? 'edit' : 'detail',
                    item: item,
                  })
                "
                @click="getId(item.project.id)"
                elevation="3"
                class="mt-4 pa-2"
                v-for="(item, index) in desserts"
                :key="index"
              >
                <div class="d-flex justify-space-between align-center">
                  <div>
                    {{ item.project.projectName
                    }}<span class="item-number"
                      >【{{ item.project.projectSerial }}】</span
                    >
                  </div>
                  <div>
                    <v-btn
                      outlined
                      x-small
                      color="#0BB1EE"
                      style="margin-right: 5px"
                      >我的会审</v-btn
                    >
                  </div>
                </div>
                <div class="text-caption grey--text">
                  {{ item.project.location }}
                </div>
                <div class="text-caption grey--text">
                  申请人: {{ item.project.createUser }} 申请时间:
                  {{ item.project.createTime }}
                </div>
              </v-card>
              <!-- <v-card
                @click.stop="
                  _itemAtcion({
                    type: toggle_index ? 'edit' : 'detail',
                    item: item,
                  })
                "
                elevation="3"
                class="mt-4 pa-2"
                v-for="item in desserts"
                :key="item.project.id"
              >
                <div class="d-flex justify-space-between align-center">
                  <div>
                    {{ item.project.projectName
                    }}<span class="item-number"
                      >【{{ item.project.projectSerial }}】</span
                    >
                  </div>
                  <div>
                    <v-btn
                      outlined
                      x-small
                      color="#0BB1EE"
                      style="margin-right: 5px"
                      >我的会审</v-btn
                    >
                  </div>
                </div>
                <div class="text-caption grey--text">
                  {{ item.project.location }}
                </div>
                <div class="text-caption grey--text">
                  申请人: {{ item.project.createUser }} 申请时间:
                  {{ item.project.createTime }}
                </div>
              </v-card> -->
            </div>
            <div class="text-center pt-3" v-if="reload">
              <v-btn
                class="mx-auto"
                fab
                dark
                small
                color="primary"
                @click="_reload"
              >
                <v-icon dark> mdi-restore </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// import AppBar from '@/components/app-bar.vue';
import {
  getProjectList,
  getInfrastructureList,
  getIdentificationList,
} from "@/api/api";

export default {
  props: ["scroll_data"],
  data() {
    return {
      project_sign: true,

      rules: {
        required: (value) => !!value || "必填",
      },
      search_data: {}, // 搜索数据
      toggle_index: 1, //视图nav id
      list_nav: [{ name: "已办事项" }, { name: "待办事项" }], // 视图nav
      headers: [
        { text: "操作", value: "action", sortable: false },
        { text: "编号", value: "project.projectSerial", sortable: false },
        { text: "工程名称", value: "project.projectName", sortable: false },
        { text: "改造地点", value: "project.location", sortable: false },
        { text: "申请人", value: "project.createUser", sortable: false },
        { text: "申请时间", value: "project.createTime", sortable: false },
        { text: "状态", value: "project.auditStatusCn", sortable: false },
        { text: "本轮会审情况", value: "currentExamine", sortable: false },
      ], // 列表头
      desserts: [], // 列表数据
      count: 0,
      server_pages: 0,
      reload: false,
      isShowOpinion: false,
      infrastructureList: [],
      projectListNow: [],
      id: 0,
      total: 0,
      todoFlagN: 0,
      ok:sessionStorage.getItem('ok')
    };
  },
  /* import引入的组件 */
  //   components: { AppBar },
  /* 监听属性 类似于data概念 */
  computed: {},
  /* 监控data中的数据变化 */
  watch: {
    toggle_index(val,oldVal) {
      console.log("inputVal = " + val + " , oldValue = " + oldVal);
      this.count = 0;
      this.load();
      sessionStorage.setItem('toggle_index',val)
      // //console.log(111111111)
      // this.load();
      // //console.log(11111)
    },
    
  },
  /* 方法集合 */
  methods: {
    getId(id) {
      this.id = id;
      // //console.log(this.id);
      sessionStorage.setItem("projectId", this.id);
    },
    objToArr(obj) {
      var arr = [];
      for (var i in obj) {
        var newObj = {};
        newObj[i] = obj[i];
        arr.push(newObj);
      }
      return arr;
    },
    writeComments() {
      // //console.log(11111111111111);
    },
    handleKeyUp() {
      let self = this;
      self.desserts = [];
      self._getProjectList();
    },
    // 行操作
    _itemAtcion({ type, item }) {
      let self = this;
      switch (type) {
        case "edit":
          self.$router.push({
            path: "/edit",
            name: "Edit",
            query: { type: "edit", project_id: item.project.id },
          });
          break;
        case "detail":
          self.$router.push({
            path: "/edit",
            name: "Edit",
            query: { type: "detail", project_id: item.project.id },
          });
          break;
        default:
          break;
      }
    },
    _reload() {
      let self = this;
      self.count = 1;
      self.server_pages = 0;
      self.search_data.search = "";
      self.project_sign = true;
      self.reload = false;
      self.desserts = [];
      // //console.log("reload");
      self.load();
    },
    _getProjectListAct2() {
      let self = this;
      self.count = 0;
      self.server_pages = 0;
      self.desserts = [];
      self.reload = false;
      self.project_sign = !self.project_sign;
      // //console.log("reload two");
      self.load();
    },

    load() {
      //console.log("count=", this.count, "server_pages", this.server_pages);
      // //console.log(this.count);
      let self = this;
      if (self.count === 0) {
        self.count += 1;
        self._getProjectList();
        //console.log(33333333333333);
        return;
      }
      // else if (this.count < this.total) {
      //   self.count += 1;
      //   self._getProjectList();
      //   return;
      // }
      else if (self.server_pages > self.count) {
        self.count += 1;
        self._getProjectList();
        //console.log(2222222222222);
        return;
      } else if (this.count <= this.total && this.server_pages >= this.count) {
        // self._getProjectList();
        self.reload = true;
        //console.log("小于哦");
        self.count += 1;
        self._getProjectList();
        return;
      } else if (self.server_pages >= self.count) {
        self.reload = true;
        //console.log(111111);
        return;
      } else {
        //console.log(444444444444444);
        self._getProjectList();
        self.reload = true;
        // //console.log(111111)
        return;
      }
      // if (self.count >= self.server_pages && self.server_pages > 0) {
      //   // //console.log(777)
      //   self.reload = true
      //   return
      // } else {
      //   // //console.log(999)
      //   self.count += 1
      //   self._getProjectList()
      // }
    },

    /**
     *〈 申请记录 -> 获取项目列表 〉
     */
    _getProjectList() {
      // //console.log("load...");
      
      let self = this;
      //self.project_sign = !self.project_sign;
      let params = {
        // auditStatusCode: self.toggle_index, // 审核状态 0-草稿 1-退回修改 2-会审中 3-会审通过 4-终止
        currentPage: self.count, // 当前页:默认值1
        pageSize: 10, // 每页条数:默认值10
        search: self.search_data.search, // 搜索框内容
        // startDate: self.search_data.startDate, // 开始时间
        // endDate: self.search_data.endDate, // 结束时间
        todoFlag: self.toggle_index + 1, // 	1-已办 2-待办
        createTimeSortType: self.project_sign ? "DESC" : "ASC", // 结束时间
      };
      this.todoFlagN = params.todoFlag;
      // //console.log(self.count,'-->')
      // if (self.search_data.search !== '' || self.project_sign !== '') {
      //   self.desserts = [];
      // }
      let loading = self.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getProjectList(params)
        .then((res) => {
          loading.close();
          if (res) {
            //console.log("==>", self.desserts, res.data);
            let resultNUM = 0;
            res.data.forEach((item) => {
              if (self.desserts.length) {
                self.desserts.forEach((item2) => {
                  // //console.log(item.project.id, item2.project.id);
                  if (item.project.id == item2.project.id) {
                    // self.desserts.push(item);
                    resultNUM=-1
                  }
                });
                if(resultNUM!=-1){
                   self.desserts.push(item);
                }
                // resultNUM = self.desserts.indexOf(item);
                // if(resultNUM==-1){
                //   self.desserts.push(item);
                // }
              } else {
                self.desserts.push(item);
              }
            });

            this.projectListNow = res.data;
            self.server_pages = res.currentPage;
            this.total = Math.ceil(res.total / res.pageSize);
            // self.pageData.server_length =Math.ceil(res.total/res.pageSize);
            // res.data.forEach((element) => {
            //   self.desserts.push(element);
            // });

            getIdentificationList().then((res) => {
              if (res.code == "0000") {
                // //console.log(res.data);
                //  //console.log();
                this.flag = this.objToArr(res.data);
              }
            });
          }
          setTimeout(() => {
            this.flag.forEach((item) => {
              for (var i in item) {
                item.id = i;
                item.show = item[i];
              }
            });
            sessionStorage.setItem("flag", JSON.stringify(this.flag));
            this.projectListNow.forEach((item) => {
              this.flag.forEach((item2) => {
                if (item.project.id == item2.id) {
                  item.show = item2.show;
                }
              });
            });
            // //console.log(this.projectListNow)
          }, 500);
        })
        .catch((err) => {
          loading.close();
          console.log(err);
        });
    },
    // 拿到基建专员名单
    getInfrastructureList() {
      let data = {
        currentPage: 1,
        pageSize: 50,
        search: "",
      };
      getInfrastructureList(data).then((res) => {
        if (res.code == "0000") {
          this.infrastructureList = res.data.records;
          // //console.log(111111111111);
        }
      });
    },
  },
  /* 生命周期 - 创建完成（可以访问当前this实例） */
  created() {
    // let self = this;
    // self.getInfrastructureList();
    document.title = "施工图会审系统 - 会审列表";
  },
  /* 生命周期 - 挂载完成（可以访问DOM元素） */
  mounted() {
    // this.getInfrastructureList();
    sessionStorage.setItem('toggle_index',this.toggle_index)
  },
  /* 生命周期 - 创建之前 */
  beforeCreate() {},
  /* 生命周期 - 挂载之前 */
  beforeMount() {},
  /* 生命周期 - 更新之前 */
  beforeUpdate() {},
  /* 生命周期 - 更新之后 */
  updated() {},
  /* 生命周期 - 销毁之前 */
  beforeDestroy() {},
  /* 生命周期 - 销毁完成 */
  destroyed() {},
  /* 如果页面有keep-alive缓存功能，这个函数会触发 */
  activated() {},
};
</script>
<style lang="scss" scoped>
.item-number {
  color: #006a91;
}
</style>
