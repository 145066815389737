import http from '@/util/http';
import http2 from '@/util/http2';

/**
 *〈 cas 〉
 */
export function cas(params) {
  return http({
    url: '/api/cas',
    method: 'get',
    params: params
  });
}
// 撤回审批
// /project/withdraw
export function withdraw(params) {
  return http({
    Authorization: true,
    url: `/api/project/withdraw?projectSerial=${params.projectSerial}`,
    method: 'post'
  });
}

/**
 *〈 user 〉
 */
export function getUser() {
  return http({
    Authorization: true,
    url: '/api/user/info',
    method: 'get'
  });
}

/**
 *〈 role 〉
 */
export function getRoleList() {
  return http({
    Authorization: true,
    url: '/api/user/role',
    method: 'get'
  });
}

/**
 *〈 申请记录 -> 获取项目列表 〉
 */
export function getProjectList(params) {
  return http2({
    Authorization: true,
    url: '/api/project/list',
    method: 'post',
    data: params
  });
}

/**
 *〈 申请记录 -> 新增/编辑 〉
 */
export function setProject(params) {
  return http({
    Authorization: true,
    url: '/api/project',
    method: 'post',
    data: params
  });
}

/**
 *〈 申请记录 -> 删除 〉
 */
export function delProject(params) {
  return http({
    Authorization: true,
    url: '/api/project/delete',
    method: 'delete',
    data: params
  });
}

/**
 *〈 申请记录 -> 终止 〉
 */
export function endProject(params) {
  return http({
    Authorization: true,
    url: `api/project/end/${params.id}`,
    method: 'get'
  });
}

/**
 *〈 申请记录 -> 下载审批记录及施工图 〉
 */
export function downloadPdf(params) {
  return http({
    Authorization: true,
    url: `api/project/downloadPdf/${params.id}`,
    method: 'post'
  });
}

/**
 *〈 申请记录 -> 详情 〉
 */
export function getProjectDetail(params) {
  return http({
    Authorization: true,
    url: `api/project/${params.id}`,
    method: 'get'
  });
}

/**
 *〈 申请记录 -> 施工文件上传 〉
 */
export function setProjectUploadWorkDraw(params) {
  return http({
    Authorization: true,
    url: '/api/project/uploadWorkDraw',
    method: 'post',
    data: params
  });
}

/**
 *〈 内容陈述 -> 获取列表 〉
 */
export function getContentPresentList(params) {
  return http({
    Authorization: true,
    url: '/api/contentPresent/list',
    method: 'post',
    data: params
  });
}

/**
 *〈 内容陈述 -> 新增/编辑 〉
 */
export function setContentPresent(params) {
  return http({
    Authorization: true,
    url: '/api/contentPresent',
    method: 'post',
    data: params
  });
}

/**
 *〈 内容陈述 -> 删除 〉
 */
export function delContentPresent(params) {
  return http({
    Authorization: true,
    url: '/api/contentPresent/delete',
    method: 'delete',
    data: params
  });
}

/**
 *〈 会审批准 -> 提交 〉
 */
export function setProjectAudit(params) {
  return http({
    Authorization: true,
    url: '/api/project/audit',
    method: 'post',
    data: params
  });
}

/**
 *〈 会审人员 -> 列表 〉
 */
export function getAssessorList(params) {
  return http({
    Authorization: true,
    url: '/api/assessor/list',
    method: 'post',
    data: params
  });
}

/**
 *〈 会审人员 -> 编辑 -> 部门人员列表 〉
 */
export function getDeptUserList(params) {
  return http({
    Authorization: true,
    url: '/api/user/list',
    method: 'post',
    data: params
  });
}

/**
 *〈 会审人员 -> 新增/编辑 〉
 */
export function setAssessor(params) {
  return http({
    Authorization: true,
    url: '/api/assessor',
    method: 'post',
    data: params
  });
}

/**
 *〈 会审人员 -> 删除 〉
 */
export function delAssessorList(params) {
  return http({
    Authorization: true,
    url: '/api/assessor/delete',
    method: 'post',
    data: params
  });
}
// 获取基建专员列表
export function getInfrastructureList(params) {
  return http({
    Authorization: true,
    url: '/api/infrastructure/list',
    method: 'post',
    data: params
  });
}

// 查询填写资料标签是否显示
export function isInfrastructure(params) {
  return http({
    Authorization: true,
    url: `/api/project/isInfrastructure?projectId=${params}`,
    method: 'get',
  });
}

// 获取项目标识
// /project/identification
export function getIdentificationList() {
  return http({
    Authorization: true,
    url: `/api/project/identification`,
    method: 'get',
  });
}

// 退回意见内容
export function returnInfrastructure(params) {
  return http({
    Authorization: true,
    url: `/api/infrastructure/return?content=${params.content}&projectId=${params.projectId}`,
    method: 'get',
  });
}