import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import { Message, MessageBox, Notification, InfiniteScroll, Loading  } from 'element-ui';

import '@/util/auth';

import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/style/common.scss';
import '@/assets/style/global.scss';
import '@mdi/font/css/materialdesignicons.min.css'

Vue.use(InfiniteScroll) 

Vue.config.productionTip = false;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
Vue.prototype.$loading = Loading.service;

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
