import axios from 'axios';
import tools from '@/util/tools';
import { Notification } from 'element-ui';

const http = axios.create({});

http.defaults.retry = 4;
http.defaults.retryDelay = 2000;

http.interceptors.request.use(
  (config) => {
    if (config.Authorization) {
      config.headers.token = tools.getUsetInfo().token;
      config.headers.roleId = tools.getUsetInfo().roles[0].id;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

/* 请求超时 */
http.interceptors.response.use(
  (result) => {
    if (result.data.code == '1001'||result.data.code == '1007') {
      tools.logout()
      return
    } else if (result.data.code !== '0000') {
      // Notification({ type: 'error', title: `Error ${result.data.code}`, message: `${result.config.url}  ${result.data.msg}` });
    }
    return result.data;
  },
  function axiosRetryInterceptor(err) {
    var config = err.config;
    Notification({ type: 'error', title: `Error ${err.response.status}`, message: `${err.config.url}  ${err.response.statusText}` });
    if (err && err.response.status) {
      switch (err.response.status) {
        case 405:
          err.message = `服务器异常(${err.response.status})!`;
          break;
        case 401:
          err.message = `授权失败，请重新登录(${err.response.status})!`;
          break;
        case 500:
          err.message = `服务器连接失败，请稍后再试！(${err.response.status})`;
          break;
        default:
          err.message = `连接出错(${err.response.status})!`;
      }
      return Promise.reject(err);
    }
    // 如果配置不存在或未设置重试选项，reject(err)
    if (!config || !config.retry) return Promise.reject(err);

    // 设置变量记录重试请求次数
    config.__retryCount = config.__retryCount || 0;

    // 检测重试请求次数
    if (config.__retryCount >= config.retry) {
      // Reject with the error
      return Promise.reject(err);
    }

    // Increase the retry count
    config.__retryCount += 1;

    // Create new promise to handle exponential backoff
    var backoff = new Promise(function(resolve) {
      setTimeout(function() {
        resolve();
      }, config.retryDelay || 1);
    });

    // Return the promise in which recalls axios to retry the request
    return backoff.then(function() {
      return axios(config);
    });
  }
);

export default http;
